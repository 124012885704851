<template>
	
	<div class="login-main" v-show="saasId > 1">
		
		<!--启动界面-->
		<div class="boot" :class="{hide: hideClass}" v-show="showLogo">
			<div class="box">
				<img class="logo-boot" v-show="logo" style="width: 100px;height: 100px" :src="logo" alt="">
			</div>
		</div>
		
		<!--logo-->
		<div class="title-box">
			<img :src="logo"/>
			<p class="project-name">{{projectName}}</p>
		</div>
		
		<div class="login-page"  style="padding-top: 3rem">
			
			<div class="title-banner">
				<h1>{{loginTitle}}</h1>
				<div>
					<el-carousel trigger="click" class="banner">
						<el-carousel-item v-for="bannerUrl in banner" :src="bannerUrl" :key="bannerUrl">
							<img :src="bannerUrl"/>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
			
			<div class="login-box">
				<div class="login-type">
					<h2 :class="{typeActive:activeType==='login'}" @click="activeType='login'">账户登录</h2>
					<h2 :class="{typeActive:activeType==='register'}" @click="activeType='register'">注册</h2>
				</div>
				<div class="login-content" v-show="activeType==='login'" style="background: linear-gradient(-45deg, #f4fcff, #ffffff, #f3fcff, #eefff8);">
					<!--账户密码登录-->
					<el-form ref="form">
						<el-form-item label="账号">
							<i class="el-icon-user" style='font-weight:900'></i>
							<el-input type="medium" v-model="name" placeholder="请输入账号"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<i class="el-icon-lock"></i>
							<el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
						</el-form-item>
						
						<el-button size="medium" @click="login" type="primary" class="login-button">登录</el-button>
					</el-form>
				</div>
				
				<div class="login-content register" v-show="activeType==='register'" style="background: linear-gradient(-45deg, #f4fcff, #edfdff, #f3fcff, #f4fffb);">
					<!--账户密码登录-->
					<el-form ref="form">
						<el-form-item label="账号">
							<i class="el-icon-user" style='font-weight:900'></i>
							<el-input type="medium" v-model="name" placeholder="请输入账号"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<i class="el-icon-lock"></i>
							<el-input v-model="password" type="password" placeholder="请输入密码"></el-input>
						</el-form-item>
						<el-form-item label="确认密码">
							<i class="el-icon-lock"></i>
							<el-input v-model="passwordConfirm" type="password" placeholder="请输入密码"></el-input>
						</el-form-item>
						<el-form-item label="验证码" class="verify-code">
							<el-input v-model="verifyCode" placeholder="请输入验证码"></el-input>
							<img :src="imgVerify" alt="" @click="initVerify"/>
						</el-form-item>
						
						<el-button size="medium" @click="register" type="primary" class="login-button">立即注册</el-button>
					</el-form>
				</div>
			</div>
			
		</div>
	</div>
</template> 
<script>
import {getApiBaseUrl, getRsaCode, isPcClient} from "@/utils/utils.js";
import {getCookie, setCookie, setToken} from '@/utils/auth'

export default {
  data() {
    return { 
      name: '',
      password: '',
	    passwordConfirm: '',
	    logo:'',
	    showLogo: true,
	    hideClass: false,
	    projectName: '',
	    loginTitle: '',
	    
	    banner:[],
	    activeType:'login',
	    
	    imgVerify:"",
	    thisRandom: 0,
	    verifyCode:"",
	    
	    saasId: '',
    };
  },
  mounted() {
		this.saasId = getCookie('saasId')
	  setCookie('userType', 'user')
	  this.initVerify()
	  
	  this.$http.post('/client/common/getPublicConfig').then(res => {
		  if (res.code === 0) {
			  res.data.list.forEach(item => {
				  if (item.key === 'logo') {
					  this.logo = getApiBaseUrl()+item.value
				  }
					if (item.key === 'projectName') {
						this.projectName = item.value
					}
					if (item.key === 'loginTitle') {
						this.loginTitle = item.value
					}
					if (['banner.banner1Url','banner.banner2Url','banner.banner3Url'].indexOf(item.key) !== -1) {
						this.banner.push(getApiBaseUrl()+item.value)
					}
			  })
		  }
	  })
	  
	  setTimeout(() => {
		  this.hideClass = true
		  setTimeout(() => {
			  this.showLogo = false
		  }, 500)
	  }, 2000)
  },
  methods: {
		initVerify() {
			let min = 100000
			let max = 999999
			min = Math.ceil(min);
			max = Math.floor(max);
			this.thisRandom = (Math.floor(Math.random() * (max - min + 1)) + min).toString();
			this.imgVerify = getApiBaseUrl() + '/common/getVerify?prefix=register&userId=' + this.thisRandom
			this.$forceUpdate()
		},
    async login() {
        if (!this.name) {
            this.$message({
                message: "请输入账号",
            });
            return
        }
        if (!this.password) {
            this.$message({
                message: "请输入密码",
            });
            return
        }

        this.$http.post('/client/user/login', {
          name: this.name,
          password: getRsaCode(this.password)
        }).then(res => {
          setToken(res.data.token)
          //获取用户信息
          this.$http.post('/client/user/info', {}).then(res => {
            let dirList = res?.data?.userInfo?.localDir
            if (dirList?.length > 0) {
              dirList.forEach(dir => {
                if (isPcClient()) {
                  pywebview.api.searchFileInPath(dir).then(res => {})
                }
              })
            }
          })

          this.$router.push('/').then(res => {
							this.$forceUpdate()
              window.location.reload()
					})
        })
    },
	  async register() {
		  
		  if (!this.name) {
			  this.$message({
				  message: "请输入账号",
			  });
			  return
		  }
		  if (!this.password) {
			  this.$message({
				  message: "请输入密码",
			  });
			  return
		  }
		  if (this.passwordConfirm !== this.password) {
			  this.$message({
				  message: "两次密码不一致",
				  type: 'error'
			  });
			  return
		  }
		  
		  this.$http.post('/client/user/register', {
			  name: this.name,
			  password: getRsaCode(this.password),
			  verifyCode: this.verifyCode,
			  random:this.thisRandom
		  }).then(res => {
			  if (res.code !== 0) {
				  console.log('注册出错', -2)
			  } else {
				  this.$message({
					  message: "注册成功",
					  type: 'success'
				  });
					this.activeType = 'login'
			  }
		  }).finally(() => {
			  this.initVerify()
		  })
	  },
  },
};
</script>
<style lang="scss" scoped>
.login-page {
  width: 100%;
	min-height: 100vh;
  display: flex;
	flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}
.boot {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 1000;
	transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
	.box {
		display: flex;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		background: linear-gradient(-45deg, #cdf1ff, #d5fbff, #e0f7ff, #d5fff4);
		background-size: 800% 800%;
		animation: gradient 6s ease infinite;
		transition: 0.5s ease-in-out, 0.5s ease-in-out;
		
		.logo-boot {
			animation: fadeIn 0.2s;
		}
		@keyframes fadeIn {
			from {opacity: 0;}
			to {opacity: 1;}
		}
	}
	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}
}
.boot.hide{
	opacity: 0;
}

.login-box {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	padding: 2rem 2rem;
	animation: fadeIn 0.5s;
	
	.login-content {
		padding: 2rem;
		border: solid 1px #eee;
		border-radius: 10px;
		width: 20rem;
		animation: fadeIn 0.5s;
		
		.login-button {
			width: 100%;
			border-radius: 2rem;
			line-height: 0.4rem;
			margin-top:1rem
		}
		::v-deep .el-input__inner {
			height: 2.3rem;
		}
	}
	.register {
		::v-deep .el-input__inner {
			height: 2rem !important;
		}
	}
	.login-type {
		display: flex;
		justify-content: flex-start;
		width: 100%;
		margin-bottom: 0.7rem;
		h2 {
			margin: auto 0.4rem;
			color:#555;
			cursor: pointer;
		}
		.typeActive {
			color:#333;
			border-bottom: solid 1px #333;
		}
	}
}
.login-box:hover {
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}

.login-main {
	background: linear-gradient(45deg, #d8fcff, #ffffff, #c3ffef);
	background-size: 150% 150%;
}
.title-banner {
	display: flex;
	justify-content: space-evenly;
	align-content: space-between;
	align-items: center;
	flex-direction: column;
	height: 80%;
	overflow: hidden;
	h1 {
		margin-bottom:2rem
	}
	::v-deep .el-carousel__item {
		overflow: hidden;
		img {
			min-height: 100%;
			max-width: 100%;
		}
	}
}
.banner {
	width: 20rem;
}
@media (max-width: 540px) {
	.title-banner {
		height: 50%;
	}
}
.title-box {
	position: fixed;
	top:0;
	left:0;
	display: flex;
	justify-content: flex-start;
	img {
		width: 2rem;
		margin: 1rem
	}
	.project-name {
		font-size: 1.3rem;
		font-weight: 900;
		line-height: 4rem;
		color: black
	}
}
.verify-code {
	::v-deep .el-form-item__label {
		width: 100%;
		text-align: left;
	}
	::v-deep .el-form-item__content {
		display: flex;
		width: 100%;
		.el-input {
			width: 45%;
			margin-right: 5%;
		}
		img {
			width: 45%;
			border-radius: 5%;
		}
	}
}
</style>